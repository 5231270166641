.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html { scroll-behavior: smooth; }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.feature {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.feature__circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.feature__icon {
  color: #737373;
}

.feature__label {
  white-space: nowrap;
}
@keyframes neon-glow {
  0%, 100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 70px #fff, 0 0 80px #fff, 0 0 100px #fff, 0 0 150px #fff;
  }
  50% {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 35px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 75px #fff;
  }
}

.neon-text {
  color: #fff;
  font-size: 10vw;
  font-weight: bold;
  animation: neon-glow 2s ease-in-out infinite alternate;
}

.neon-card {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 255, 255, 0.3), 0 0 30px rgba(0, 255, 255, 0.1);
}

.neon-icon {
  filter: drop-shadow(0 0 5px currentColor) drop-shadow(0 0 10px currentColor);
}

.neon-text {
  position: absolute;
  white-space: nowrap;
  font-size: 20vw;
  opacity: 0.1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  animation: neon-move 20s linear infinite;
}

@keyframes neon-move {
  0% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(-100%, -50%);
  }
}
/* Add this to your existing CSS */

.neon-button {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 255, 255, 0.5), 0 0 10px rgba(0, 255, 255, 0.3);
  text-shadow: 0 0 5px #fff;
  transition: all 0.3s ease;
}

.neon-button:hover {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

/* Adjust the existing neon-card class */
.neon-card {
  transition: all 0.3s ease;
}

.neon-card:hover {
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.5), 0 0 30px rgba(0, 255, 255, 0.3);
}

/* Style for the modal */
.max-h-90vh {
  max-height: 90vh;
}

.reduced-glow {
  box-shadow: 0 0 5px rgba(0, 255, 255, 0.5), 0 0 10px rgba(0, 255, 255, 0.3);
}


.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.pricing-card:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}

.neon-button-white {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
}

.neon-button-white:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.border-gradient-animate {
  position: relative;
}

.border-gradient-animate::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff, #00ffff);
  background-size: 400% 400%;
  z-index: -1;
  filter: blur(5px);
  animation: gradientBorder 15s ease infinite;
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.pricing-card:hover .neon-text {
  text-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff;
}

.pricing-card ul {
  flex-grow: 1;
}

.pricing-card .neon-button {
  transition: all 0.3s ease;
}

.pricing-card:hover .neon-button {
  box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 40px #0ff;
}
.pricing-card {
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.pro-card {
  position: relative;
  background: linear-gradient(45deg, #B57BFF, #1E90FF);
}

.pro-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #B57BFF, #1E90FF, #B57BFF, #1E90FF);
  background-size: 400% 400%;
  z-index: -1;
  filter: blur(5px);
  animation: gradientBorder 15s ease infinite;
  border-radius: inherit;
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .pricing-card {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}
@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-5px, 5px);
  }
  40% {
    transform: translate(-5px, -5px);
  }
  60% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0);
  }
}

.glitch-text {
  animation: glitch 5s infinite;
}

@media (max-width: 768px) {
  .glitch-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 20vw !important;
  }
}

.pricing-card {
  transition: all 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.black-background {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glassmorphic-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  /* Add other styles as needed */
}

/* New styles for the glassmorphic footer card */
.glassmorphic-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.glassmorphic-card:hover {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Ensure the body has a black background */
body {
  background-color: #000000;
  min-height: 100vh;
}

/* Add some texture to the background */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.02' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  z-index: -1;
}

.transparent-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 150px; /* Adjust as needed */
}

.black-section {
  flex: 1;
  background-color: black;
}

.glassmorphic-card {
  width: 300px; /* Adjust as needed */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  z-index: 1;
}
