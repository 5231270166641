
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('http://fonts.cdnfonts.com/css/graphik');
@import url(https://fonts.googleapis.com/css?family=Montserrat:800,200);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
 font-family:  montserrat;
                                                
    

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#bg {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center !important;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
}

#header {
  position: relative;
  z-index: 1;
}
.navbar a {
  position: relative;
  display: inline-block;
  font-size: 1em;

  color: white;
  overflow: hidden;
  background: linear-gradient(to right, #ff9100 50%, white 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}
.nav-mobile a{
  position: relative;
  display: inline-block;


  color: black;
  overflow: hidden;
  background: linear-gradient(to right, #ff9100 50%, white 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}

a:hover {
  background-position: 0 100%;
}
:root {
  --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 50%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);

  --font-family: "montserrat", system-ui;

  --bg-gradient: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:800,200);                  
.boss{
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  font-family: montserrat;
  overflow-behavior-y:contain;
  scroll-snap-type: y mandatory;
}
.child{
  scroll-snap-align:center;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.orb-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.side nav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  z-index: 10;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1920px;
  
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(100px);
  transform: scale(-1,1);
}




.button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  display: grid;
}

.side ul,
.side li {
  margin: 0;
  padding: 0;
}

.side ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.side li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.text-gradient {
  background-image: linear-gradient(
    45deg,
    var(--base) 25%,
    var(--complimentary2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.wrapper{
  box-sizing: content-box;
  /* background-color: #ffffff; */
  /* height: 70px; */
  /* padding: 50px 30px; */
  display: flex;
  border-radius: 8px;
  /* box-shadow: 0 20px 25px rgba(0,0,0,0.2); */
}
.words{
  overflow: hidden;
}
.span{
  display: block;
  height: 100%;
  /* padding-left: 10px; */
  width: auto;
  /* color: #0e6ffc; */
  animation: spin_words 6s infinite;
}
@keyframes spin_words{
  10%{
      transform: translateY(-112%);
  }
  25%{
      transform: translateY(-100%);
  }
  35%{
      transform: translateY(-212%);
  }
  50%{
      transform: translateY(-200%);
  }
  60%{
      transform: translateY(-312%);
  }
  75%{
      transform: translateY(-300%);
  }
  85%{
      transform: translateY(-412%);
  }
  100%{
      transform: translateY(-400%);
  }
}
@import url(https://fonts.googleapis.com/css?family=Montserrat:800,200);
 .landing2 {
	 font-family: montserrat;
	 overflow-x: hidden;
	 width: 100%;
	 height: 100vh;
   margin-top: 100px;
	 display: flex;
	 align-items: center;
	 background: black;
	 line-height: 1.7;
	 color: #fff;
	 transform: skewY(-5deg);
}
 .bg-text-container {
	 transform: translateX(-50%);
	 left: 50%;
	 position: absolute;
	 z-index: -999;
}
 @keyframes text-scrolling {
	 0% {
		 transform: translate3d(-100%, 0, 0);
	}
	 100% {
		 transform: translate3d(0%, 0, 0);
	}
}
 .animate-text {
	 animation: text-scrolling 20s linear infinite;
	 will-change: transform;
	 display: block;
	 position: relative;
	 white-space: nowrap;
}
 .animate-text.left {
	 animation-direction: reverse;
}
 .spaner {
	 font-size: 280px;
	 color: transparent;
	 -webkit-text-stroke: 2px #30442a;
	 text-transform: uppercase;
	 display: inline-block;
	 line-height: 0.75;
	 min-width: auto;
	 font-weight: 800;
}
 .container {
	 padding: 30px;
	 max-width: 1000px;
	 width: 100%;
	 margin: 0 auto;
}
 .container .col {
	 max-width: 600px;
	 margin: 0;
}
 .h1 {
	 font-size: 90px;
	 margin: 0;
}
 .p {
	 font-size: 18px;
	 font-weight: 200;
	 margin: 0;
}
.modal {
  min-height: 600px;
  border-radius: 20px;
  position: relative;
  background-color: #212121;
  box-shadow: #121212 0px 8px 24px;
  display: flex;
  color: white;
}

.modal__image {
  object-fit: cover;
  border-radius: 20px;
}

.modal__info {
  background-color: #212121;
  display: flex;
}

.modal__row {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  text-align: left;
}

.modal__description-wrapper {
  margin: 20px 0px;
  text-align: left;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.modal__price {
  font-size: 3rem;
}

.modal__address {
  font-size: 1.5rem;
  color: #cec9c9;
}

.modal__description {
  color: #c2c1c1;
  margin: 0px;
  padding: 0px;
  font-size: 1.25rem;
}

.modal__close-wrapper {
  all: unset;
  position: absolute;
  cursor: pointer;
}

.modal__close-icon {
  width: 40px;
  height: 40px;
}

@media only screen {
  .modal {
    width: 90%;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .modal__image {
    width: 100%;
  }

  .modal__info {
    width: calc(100%);
    height: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal__close-wrapper {
    right: -20px;
    top: -30px;
  }

  .modal__close-icon {
    width: 35px;
    height: 35px;
    color: #ffffff;
  }
}

@media only screen and (min-width: 1281px) {
  .modal {
    width: 80%;
    height: 80%;
    max-width: 1800px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 30px;
  }

  .modal__image {
    width: calc(70% - 10px);
    height: 100%;
  }

  .modal__info {
    width: calc(30% - 10px);
    height: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal__close-wrapper {
    right: 10px;
    top: 10px;
  }

  .modal__close-icon {
    color: #ffffff;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
}

@media only screen {
  .overlay {
    overflow-y: scroll;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 0px;
  }
}

@media screen and (min-width: 1281px) {
  .overlay {
    justify-content: center;
    align-items: center;
  }
}




.price {
  min-height: 100vh;
  font-family: montserrat;
    font-weight: 500;
  background: black
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

}

.carder {
  max-width: 600px;
  width: auto;
  margin:  10px;
  background-color: #ff6e00;
    color: white;
  padding: 3em 2rem;
  cursor: pointer;
}

.carder p {
  width: 45ch;
  padding-top: 1em;
  line-height: 1.5;
}

.expander {
  width: inherit;
}

.btn-primary {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #26272c;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.35);
}
